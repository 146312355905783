<script lang="ts">
    import { setContext, onMount } from 'svelte';
    import SettingsNav from '@/Components/settings/SettingsNav.svelte';
    import SettingsMobileNav from '@/Components/settings/SettingsMobileNav.svelte';
    import SettingsHeader from '@/Components/settings/SettingsHeader.svelte';
    import SettingsProfile from '@/Components/settings/SettingsProfile.svelte';
    import SettingsSecurity from '@/Components/settings/SettingsSecurity.svelte';
    import SettingsConnection from '@/Components/settings/SettingsConnection.svelte';
    import SettingsRates from '@/Components/settings/SettingsRates.svelte';
    import SettingsSubscription from '@/Components/settings/SettingsSubscription.svelte';
    import SettingsReferrals from '@/Components/settings/SettingsReferrals.svelte';
    import SettingsNotifications from '@/Components/settings/SettingsNotifications.svelte';
    import SettingsPrivacy from '@/Components/settings/SettingsPrivacy.svelte';
    import SettingsVerify from '@/Components/settings/SettingsVerify.svelte';
    import SettingsVipVerify from '@/Components/settings/SettingsVipVerify.svelte';
    import type { User, UserVerify } from '@/interfaces/general';
    import type { AvailableSettings, SettingsData, SettingsTab } from '@/interfaces/settings';

    export let availableSettings: AvailableSettings;
    export let currentSettingTab: AvailableSettings;
    export let activeSettingsTab: SettingsTab;
    export let additionalAssets: string[];
    export let authUser: User;
    export let data: SettingsData;
    export let cookie_app_theme: string;
    export let default_user_theme: string;
    export let authUserVerification: UserVerify;

    export let errors: string[] = [];

    // Reactive statement to update the context whenever props change
    $: {
        const settingsContext = {
            data,
            availableSettings,
            currentSettingTab,
            activeSettingsTab,
            additionalAssets,
            authUser,
            cookie_app_theme,
            default_user_theme,
            authUserVerification,
        };
        setContext('settingsContext', settingsContext);
    }
</script>

<div class="">
    <div class="row">
        <div class="col-12 col-md-4 col-lg-3 settings-menu mb-3 pr-0">
            <div class="settings-menu-wrapper">
                <div class="d-none d-md-block">
                    <svelte:component this="{SettingsHeader}" type="generic" />
                </div>
                <hr class="m-0" />
                <div class="d-none d-md-none mt-3">
                    <svelte:component this="{SettingsHeader}" type="" />
                </div>
                <div class="d-none d-md-block">
                    <svelte:component this="{SettingsNav}" />
                </div>
            </div>
        </div>
        <div
            class="col-md-8 col-lg-9 mb-lg-0 min-vh-100 border-left border-right settings-content mt-md-0 pl-md-0 pr-md-0 mb-5 mt-1"
        >
            <div class="d-md-flex justify-content-between ml-3">
                <div>
                    <h5 class="mt-md-3 mb-0 mt-0 font-bold">
                        {activeSettingsTab.charAt(0).toUpperCase() + activeSettingsTab.slice(1)}
                    </h5>
                    <h6 class="mb-2 mt-2 text-muted">{currentSettingTab['heading']}</h6>
                </div>
            </div>
            <hr class="d-md-block m-0 mt-2" />
            <!-- settings mobile nav -->
            <SettingsMobileNav />
            <div
                class="px-md-3 px-4 pt-3 {(!authUser.email_verified_at || !authUser.birthdate) &&
                activeSettingsTab === 'verify'
                    ? 'verfyuiblur'
                    : ''}"
            >
                {#if activeSettingsTab === 'profile'}
                    <svelte:component this="{SettingsProfile}" />
                {:else if activeSettingsTab === 'security'}
                    <svelte:component this="{SettingsSecurity}" />
                {:else if activeSettingsTab === 'connections'}
                    <svelte:component this="{SettingsConnection}" />
                {:else if activeSettingsTab === 'rates'}
                    <svelte:component this="{SettingsRates}" />
                {:else if activeSettingsTab === 'subscriptions'}
                    <svelte:component this="{SettingsSubscription}" {data} />
                {:else if activeSettingsTab === 'referrals'}
                    <svelte:component this="{SettingsReferrals}" />
                {:else if activeSettingsTab === 'notifications'}
                    <svelte:component this="{SettingsNotifications}" />
                {:else if activeSettingsTab === 'privacy'}
                    <svelte:component this="{SettingsPrivacy}" />
                {:else if activeSettingsTab === 'verify'}
                    <svelte:component this="{SettingsVerify}" />
                {:else if activeSettingsTab === 'vip-verification'}
                    <svelte:component this="{SettingsVipVerify}" />
                {/if}
            </div>
        </div>
    </div>
    <slot></slot>
</div>

<!-- Use the errors prop somewhere in your component -->
{#if errors.length}
    <ul>
        {#each errors as error}
            <li>{error}</li>
        {/each}
    </ul>
{/if}
